import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import Content from '@/components/Content';
import { SimpleImage } from '@/components/Ads';

const PregnantRisks = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(`fr`);
  }, []);

  return (
    <Layout
      title="Alimentation pendant la grossesse ? Manger sereinement avec La fraise !"
      description="Alimentation de la femme enceinte grâce à La fraise. Découvrez tous les aliments interdits pendant la grossesse ? Télécharger gratuitement notre application mobile La fraise, manger enceinte."
    >
      <Content>
        <h1 className="mb-16 mr-12 text-4xl font-bold">
          Les risques liés à la grossesse
        </h1>

        <div>
          <div className="mb-16" id="toxoplasmose">
            <h2 className="text-2xl mb-8 font-bold">La toxoplasmose</h2>
            <div>
              <p>
                La toxoplasmose est une{` `}
                <strong>
                  maladie à risque pour les femmes enceintes et leurs bébés.
                </strong>
                <br />
                Elle favorise les accouchements prématurés. La "toxo" peut aussi
                causer des problèmes neurologiques ou ophtalmiques chez le
                fœtus. C'est pourquoi on prescrit systématiquement, en début de
                grossesse, un test lié à la toxoplasmose.
              </p>
              <br />
              <strong>
                Comment savoir si l'on est immunisée contre la toxoplasmose ?
              </strong>
              <br />
              <br />
              <p>
                Au-delà du test sérologique, il y a de grandes chances que vous
                soyez immunisée contre la toxoplasmose :
              </p>
              <br />
              <ul>
                <li>
                  - si, avant votre grossesse, vous étiez une mordue de viande
                  cru ;
                </li>
                <li>- ou si vous avez vécu avec un chaton.</li>
              </ul>
              <br />
              <p>
                Si vous avez des symptômes comme de la fièvre, des ganglions et
                de la fatigue après avoir mangé une viande mal cuite, vous avez
                peut-être contracté la toxoplasmose.
                <br />
                Faites une prise de sang. Vous saurez si vous êtes immunisée
                contre la toxoplasmose et éviterez tout risque pendant la
                grossesse.
              </p>
              <br />
              <strong>
                Comment se prémunir contre la toxoplasmose quand on n'est pas
                immunisée ?
              </strong>
              <br />
              <p>
                Si votre prise de sang a révélé que vous n'étiez pas immunisée
                contre la toxo, voici les <strong>précautions</strong> que vous
                pouvez prendre pour protéger votre bout d'chou. Évitez de manger
                :
              </p>
              <br />
              <ul>
                <li>- de la viande mal cuite, crue, fumée ou séchée ;</li>
                <li>- des charcuteries crues, fumées ou séchées ;</li>
                <li>- des fromages au lait cru ;</li>
                <li>- Les fruits de mer non cuits.</li>
              </ul>
              <br />
              <strong>Lavez bien vos mains</strong> après avoir manipulé de la
              viande ou des légumes terreux.
              <br />
              <br />
              <strong>Lavez les fruits, légumes et aromates</strong> que vous
              mangez, surtout s'ils proviennent du jardin.
              <br />
              <br />
              <p>
                Si vous <strong>avez un chat</strong>, demandez à votre
                partenaire de changer sa litière. Si vous êtes seule avec votre
                matou, changez sa litière régulièrement, en utilisant des gants.
                Et lavez vos mains après avoir caressé votre chat.
              </p>
            </div>
          </div>

          <SimpleImage
            source="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Joone/block.webp"
            sourceMobile="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Joone/block-mobile.webp"
            alt="Joone Ads"
            link="https://campaign.lafraise.app/2UYl"
          />

          <div className="mb-16" id="listeriose">
            <h2 className="text-2xl mb-8 font-bold">La listériose</h2>
            <div>
              <p>
                La listériose provient d'une <strong>bactérie</strong> qui se
                nomme Listeria monocytogenes. Elle se développe dans la
                nourriture. La listériose reste assez rare dans nos sociétés.
                Elle est en effet limitée par le nettoyage de la nourriture et
                la pasteurisation. Malgré ces précautions, la Listeria peut se
                retrouver dans certains aliments. Chez les femmes enceintes,
                contracter la listériose est{` `}
                <strong>dangereux pour bébé.</strong>
              </p>
              <br />
              <h3 className="font-bold">
                Quels sont les symptômes d'une listériose ?
              </h3>
              <br />
              <p>
                Le problème, c'est que les symptômes de la listériose sont
                proches des désagréments liés à la grossesse. Nausées,
                vomissements, maux de ventre, maux de tête sont des signes de
                listériose.{` `}
                <strong>La fièvre</strong> est un symptôme supplémentaire qui
                peut vous alerter. En cas de{` `}
                <strong>listériose enceinte</strong>, la prise d'
                <strong>antibiotiques</strong> est inévitable.
              </p>
              <br />
              <h3 className="font-bold">
                Comment éviter d'attraper la listériose ?
              </h3>
              <br />
              <ul>
                <li>
                  - Évitez tout aliment cru : viandes et poissons crus, fruits
                  de mer non cuits, graines germées crues, fromages au lait cru,
                  poissons et charcuteries fumés non cuits ;
                </li>
                <li>
                  - Lavez souvent vos mains, surtout si vous touchez des viandes
                  et poissons crus pour les cuire ;
                </li>
                <li>
                  - Quand vous mangez du fromage – à pâte cuite évidemment ! -,
                  {` `}
                  <strong>ôtez la croûte</strong> car c'est là que se
                  développent les bactéries ;
                </li>
                <li>
                  - Ne conservez <strong>pas de restes alimentaires</strong> ;
                </li>
                <li>
                  - Désinfectez régulièrement votre réfrigérateur et contrôlez
                  que sa température n'excède jamais 4°C ;
                </li>
                <li>- Évitez de décongeler des aliments à l'air ambiant.</li>
              </ul>
              <br />
              En résumé, une bonne cuisson des aliments et une parfaite hygiène
              limitent les risques de listériose. L'obésité chez la femme
              enceinte entraîne des{` `}
              <strong>risques pour la maman et le bébé</strong>.
              <br />
              <br />
              Pour la maman, les risques de développer un{` `}
              <a href="#diabete" className="underline">
                diabète gestationnel
              </a>
              , de <strong>faire de l'hypertension</strong> et de se voir
              imposer une césarienne sont plus élevés avec l'obésité. Quant au
              bébé, il risque d'arriver prématurément, de naître en surpoids et
              de subir des déformations lors de sa naissance.
            </div>
          </div>

          <div className="mb-16" id="diabete">
            <h2 className="text-2xl mb-8 font-bold">Le diabète</h2>
            <div>
              <p>
                Qu'il soit présent avant la maternité ou qu'il se développe avec
                la grossesse, le diabète peut favoriser les malformations chez
                bébé et divers problèmes de santé. Bien sûr, si vous étiez
                diabétique avant d'être enceinte, la grossesse n'arrangera pas
                la maladie. D'autres facteurs favorisent le{` `}
                <strong>diabète gestationnel</strong> :
              </p>
              <br />
              <ul>
                <li>- un précédent diabète gestationnel ;</li>
                <li>- des antécédents familiaux ;</li>
                <li>- un surpoids ;</li>
                <li>- une grossesse à plus de 35 ans.</li>
              </ul>
              <br />
              Pour limiter les risques liés au diabète de grossesse, on vous
              conseille d'adopter un régime spécifique, de pratiquer une
              activité physique régulière, de vous faire suivre assidûment par
              votre médecin et d'opter pour des{` `}
              <strong>injections d'insuline</strong> qui réguleront votre taux
              de sucre dans le sang.
            </div>
          </div>

          <div className="mb-16" id="salmonellose">
            <h2 className="text-2xl mb-8 font-bold">La Salmonellose</h2>
            <div>
              <p>
                La <strong>salmonellose</strong> est une infection intestinale
                causée par les bactéries du genre Salmonella.
              </p>
              <br />
              <p>
                Les Salmonella se retrouvent dans les selles de plusieurs
                animaux, dont les mammifères (incluant les humains), les
                reptiles, les amphibiens et les oiseaux.
              </p>
              <br />
              <p>
                Toute personne qui consomme des aliments ou de l’eau contaminés
                par des Salmonella est susceptible de{` `}
                <strong>s’infecter</strong>. Les aliments les plus souvent
                contaminés par des Salmonella sont <strong>les fruits</strong>
                {` `}
                et <strong>légumes crus</strong> (tels que les graines germées
                et les cantaloups), <strong>les œufs crus</strong>, les{` `}
                <strong>produits laitiers</strong> non pasteurisés et les{` `}
                <strong>viandes</strong> et <strong>volailles</strong>
                {` `}
                insuffisamment cuites.
              </p>
              <br />
              <p>
                L’eau est plus rarement contaminée, sauf dans les pays où les
                installations sanitaires sont déficientes.
              </p>
              <br />
              <p>
                Si la salmonellose ne va pas engendrer de complications chez la
                plupart des adultes en bonne santé, cette infection bactérienne
                peut être plus grave chez les populations au système immunitaire
                faible, comme les bébés, les femmes enceintes et les personnes
                immuno-déprimées.
              </p>
              <br />
              <p>Pour limiter le risque, il est conseillé de :</p>
              <br />
              <ul>
                <li>
                  - Conserver les aliments crus séparément des aliments cuits ;
                </li>
                <li>- Se laver les mains avant de cuisiner,</li>
                <li>
                  - Laver les aliments crus, les plans de travail et les
                  ustensiles de cuisine ;
                </li>
                <li>
                  - Réchauffer les restes alimentaires à haute température pour
                  détruire les éventuelles bactéries ;
                </li>
                <li>
                  - Nettoyer régulièrement le réfrigérateur à l’eau de javel.
                </li>
                <li>
                  - Ne pas consommer de produits laitiers au lait cru et non
                  pasteurisés, tout comme des graines germées crues, de la
                  viande ou du poisson cru.
                </li>
              </ul>
              <br />
              <p>
                Une bonne cuisson est préconisée pour les produits animaux tels
                que la viande, le poisson ou les œufs. Et il est aussi conseillé
                de suivre l’actualité, car des alertes sanitaires sont lancées
                en cas d’aliments potentiellement contaminés.
              </p>
            </div>
          </div>

          <div className="mb-16" id="intoxication-au-methylmercure">
            <h2 className="text-2xl mb-8 font-bold">
              Intoxication au méthylmercure
            </h2>
            <div>
              <p>
                Le mercure, en particulier sous sa forme organique, le{` `}
                <strong>méthylmercure</strong>, est un neurotoxique puissant
                pour le <strong>fœtus</strong>: des anomalies du développement
                psychomoteur de l'enfant ont été associées à l'exposition au
                mercure de la mère pendant la grossesse sans que celle-ci
                présente des <strong>symptômes d'intoxication.</strong>
              </p>
              <br />
              <p>
                Selon l'étude de Santé Publique France, au regard des "bénéfices
                nutritionnels liés à la consommation de poissons (acides gras
                essentiels, protéines, vitamines, minéraux et oligoéléments)",
                l’Agence recommande aux femmes enceintes de consommer du poisson
                {` `}
                <strong>deux fois par semaine maximum</strong> dont les poissons
                gras (saumon, maquereau, sardine, anchois, truite fumée,
                hareng…) et de diversifier les espèces de poissons consommées.
              </p>
              <br />
              <p>
                Elle précise d’éviter à titre de précaution de consommer les
                poissons les plus contaminés comme les requins, lamproies ou
                espadons, et de <strong>"limiter la consommation"</strong>
                {` `}
                d'autres poissons "susceptibles d'être fortement contaminés" au
                mercure, dont le thon, le brochet ou la dorade.
              </p>
            </div>
          </div>

          <div className="mb-16" id="alcoolisme-foetal">
            <h2 className="text-2xl mb-8 font-bold">Alcoolisme foetal</h2>
            <div>
              <p>
                Lorsqu’une femme consomme de l’alcool pendant qu’elle est
                enceinte, l’alcool est transmis directement au bébé par le
                circuit sanguin. La consommation d’alcool pendant la grossesse
                peut provoquer le syndrome d’alcoolisme foetal, une maladie
                grave qui peut porter atteinte à l’enfant pendant toute sa vie.
              </p>
              <br />
              <p>
                Les enfants atteints du SAF ont des troubles de développement,
                d’apprentissage, de comportement et d’aptitudes sociales. Il est
                donc préférable de ne pas consommer d’alcool du tout.
              </p>
            </div>
          </div>

          <div className="mb-16" id="hypotension">
            <h2 className="text-2xl mb-8 font-bold">Hypotension</h2>
            <div>
              <p>
                Durant le premier trimestre, la pression artérielle a tendance à
                diminuer naturellement chez la femme enceinte. En effet, le
                système circulatoire est pleinement dirigé vers le placenta et
                le fœtus tandis que les modifications hormonales importantes à
                ce stade provoquent une dilatation des artères et des veines ce
                qui entraîne une légère baisse de tension.
              </p>
              <br />
              <p>
                C’est la raison pour laquelle vous pouvez ressentir une
                sensation de vertiges ou d'étourdissements. Mais dès que
                commence le 2e trimestre, la tension se doit de retrouver un
                état normal. Elle ne subira d'éventuelles nouvelles
                modifications qu'au début du 3e trimestre, au cours duquel les
                cas d'hypertension se révèlent généralement.
              </p>
              <br />
              <h3 className="font-bold">
                Comment détecter l'hypotension pendant la grossesse ?
              </h3>
              <br />
              <ul>
                <li>- des maux de tête,</li>
                <li>- une fatigue accrue,</li>
                <li>- des sueurs froides,</li>
                <li>- des bourdonnements auditifs,</li>
                <li>- des troubles de la vision,</li>
                <li>- une pâleur du visage,</li>
                <li>- une baisse du rythme cardiaque.</li>
              </ul>
            </div>
          </div>

          <div className="mb-16" id="contractions-uterus">
            <h2 className="text-2xl mb-8 font-bold">
              Contractions de l'utérus
            </h2>
            <div>
              <p>
                Il existe différents types de contraction qui se produisent à
                différents moments de la grossesse. Chaque type de contraction a
                sa propre fonction.
              </p>
              <br />
              <p>
                Les contractions précoces sont des contractions qui peuvent
                survenir avant la 36e semaine de grossesse. On peut les
                reconnaître au fait que l’utérus se contracte environ trois fois
                par heure dans des intervalles relativement courts, et que la
                douleur s’intensifie progressivement. Ces contractions peuvent
                être accompagnées d’un écoulement aqueux, voire sanglant.
              </p>
              <br />
              <p>
                Certaines plantes peuvent favoriser l’apparition de
                contractions; elles sont à éviter.
                <br />
                Et si les contractions ne sont pas le signe d’une naissance
                prématurée, elles peuvent néanmoins être un signal d’alarme de
                votre corps vous indiquant une surcharge physique et/ou
                émotionnelle.
              </p>
              <br />
              <p>Du repos et de la relaxation pourraient vous faire du bien.</p>
            </div>
          </div>

          <div className="mb-16" id="accouchement-premature">
            <h2 className="text-2xl mb-8 font-bold">Accouchement prématuré</h2>
            <div>
              <p>
                Soda et grossesse, ce n’est pas un bon cocktail. Contrairement à
                ce qu’on peut penser, les boissons gazeuses sucrées sont acides.
                Elles favorisent donc les maux d’estomac.
                <br />
                Les sodas sucrés favorisent aussi le{` `}
                <a href="#diabete" className="underline">
                  diabète gestationnel
                </a>
                {` `}
                et augmentent les risques d’obésité maternelle. Dans des
                proportions moindres, le soda favorise la prise de poids et les
                accouchements prématurés. Une étude danoise a mis en évidence
                corrélation entre la consommation de boissons édulcorées
                contenant contenant de l’aspartame ou du sucre, et le risque
                d’accouchement prématuré.
              </p>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default PregnantRisks;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["general"] }, language: { eq: "fr" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
